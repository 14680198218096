body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f3f4f6; /* Light background color */
  color: #333;
}

.App-header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.main {
  padding: 20px;
  background-color: #f3f4f6; /* Match the body background color */
}

.about-section {
  max-width: 1000px;
  margin: 20px auto; /* Center the sections and add vertical space */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.projects-section {
  max-width: 1000px;
  margin: 20px auto; /* Center the sections and add vertical space */
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-content {
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50% 20% / 10% 40%;
  margin-right: 20px;
}

.about-text {
  max-width: 600px;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-item {
  background-color: #fff;
  padding: 15px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.App-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

.date {
  color: #909090;
  font-weight: 549;
}

.header-container {
  display: flex;
  align-items: baseline; /* Align items vertically in the center */
  margin-bottom: -20px;
  padding-bottom: 0px;
  margin-top: -15px
}

.header2 {
  font-size: small;
  font-weight: 500;
  padding-left: 5px;
}

.section-header {
  font-size:xx-large;
  margin-top: 0px;
}
